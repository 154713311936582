<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <form class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <b-form-input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </form>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(city)="data">
          {{ `${data.item.city}/${data.item.uf}` }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalEditUnit(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="modal-create-unit"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
        size="lg"
      >
        <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
          <b-row class="row">
            <b-col md="4">
              <input
                id="image"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewImage"
              />
              <b-form-group>
                <label
                  for="image"
                  class="d-block text-center mb-0"
                  style="cursor: pointer; font-size: 1.1rem"
                >
                  <div
                    v-if="!preview"
                    style="
                      height: 142px;
                      line-height: 10;
                      border: 1px solid #d1d1d1;
                      border-radius: 5px;
                    "
                  >
                    <feather-icon size="19" icon="ImageIcon" />
                    Selecionar imagem
                  </div>
                  <template v-if="preview">
                    <b-img
                      for="image"
                      :src="preview"
                      style="height: 153px"
                      fluid
                    />
                  </template>
                </label>
              </b-form-group>
            </b-col>
            <div class="col-md-8">
              <div class="form-row">
                <b-col md="5">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Nome
                  </label>
                  <div class="form-group">
                    <b-form-input
                      v-model="item.name"
                      :class="{ 'is-invalid': $v.item.name.$error }"
                    />
                  </div>
                </b-col>
                <b-col md="7">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    E-mail
                  </label>
                  <div class="form-group">
                    <b-form-input
                      v-model="item.email"
                      :class="{ 'is-invalid': $v.item.email.$error }"
                    />
                  </div>
                </b-col>
                <b-col md="4" cols="6">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Telefone 1
                  </label>
                  <div class="form-group">
                    <b-form-input
                      placeholder="(__) _____-____"
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      v-model="item.phone_1"
                      :class="{ 'is-invalid': $v.item.phone_1.$error }"
                    />
                  </div>
                </b-col>
                <b-col md="4" cols="6">
                  <label for=""> Telefone 2 </label>
                  <div class="form-group">
                    <b-form-input
                      placeholder="(__) _____-____"
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      type="tel"
                      v-model="item.phone_2"
                    />
                  </div>
                </b-col>
                <b-col md="3" cols="2">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i> 
                    ID Migração
                  </label>
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      v-model="item.id_old"
                    />
                  </div>
                </b-col>
              </div>
            </div>
          </b-row>
          <legend class="pt-2">Endereço</legend>
          <p class="mb-2">
            O endereço será usado para exibição no google maps.
          </p>
          <b-row class="form-row">
            <b-col md="2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                CEP
              </label>
              <div class="form-group">
                <b-form-input
                  type="tel"
                  @keyup="searchCep()"
                  v-mask="['#####-###']"
                  v-model="item.cep"
                  :class="{ 'is-invalid': $v.item.cep.$error }"
                />
              </div>
            </b-col>
            <b-col md="2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Número
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.number"
                  :class="{ 'is-invalid': $v.item.number.$error }"
                />
              </div>
            </b-col>
            <b-col md="3">
              <label for=""> Complemento </label>
              <div class="form-group">
                <b-form-input v-model="item.complement" />
              </div>
            </b-col>
            <b-col cols="5">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Endereço
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.address"
                  :class="{ 'is-invalid': $v.item.address.$error }"
                />
              </div>
            </b-col>

            <b-col md="3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Bairro
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.neighborhood"
                  :class="{ 'is-invalid': $v.item.neighborhood.$error }"
                />
              </div>
            </b-col>
            <b-col md="3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Cidade
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.city"
                  :class="{ 'is-invalid': $v.item.city.$error }"
                />
              </div>
            </b-col>
            <b-col md="3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                UF
              </label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="item.uf"
                  class="form-control"
                  :class="{ 'is-invalid': $v.item.uf.$error }"
                  maxlength="2"
                />
              </div>
            </b-col>

            <b-col md="3">
              <label for="">Status</label>
              <v-select
                label="title"
                :searchable="false"
                item-text="title"
                item-value="code"
                placeholder="Status"
                v-model="item.status"
                :options="optionsStatus"
                :class="{ 'is-invalid': $v.item.status.$error }"
              />
            </b-col>
          </b-row>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-2">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-unit')"
                class="btn btn-light btn-block"
              >
                Fechar
              </button>
            </div>

            <div class="col-md-3">
              <button
                :disabled="submited"
                type="submit"
                class="btn btn-success btn-block"
              >
                {{ textSubmit }}
              </button>
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BImg,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import axios from "axios";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    BModal,
    BFormGroup,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    CustomPaginateTable,
  },
  data() {
    return {
      preview: null,
      image: null,
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          thStyle: "width: 80px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "city",
          label: "Local",
          thStyle: "width: 190px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        uuid: "",
        name: "",
        email: "",
        phone_1: "",
        phone_2: "",
        id_old: "",
        address: "",
        complement: "",
        city: "",
        uf: "",
        number: "",
        cep: "",
        neighborhood: "",
        status: null,
        image: "",
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
      materialsOptions: [],
    };
  },
  validations: {
    item: {
      name: {
        required,
        minLength: minLength(3),
      },
      phone_1: {
        required,
      },
      email: {
        required,
        email,
      },
      cep: {
        required,
      },
      address: {
        required,
      },
      id_old: {
        required,
      },
      number: {
        required,
      },
      neighborhood: {
        required,
      },
      city: {
        required,
      },
      uf: {
        required,
      },
      status: {
        required,
      },
      image: {
        required: requiredIf((vm) => {
          return vm.uuid ? false : false;
        }),
      },
    },
  },
  mounted() {
    this.getData();

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });

    this.$root.$on("open:modal-create-unit", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Unidade";
      this.$bvModal.show("modal-create-unit");
    });

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-unit");
    this.$root.$off("paginate:update");
  },
  methods: {
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    async fetchMaterialsOptions(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Media/searchType", { term: term, type: 8 })
          .then(() => {
            this.materialsOptions = this.searchMaterials;
          });
      }
    },
    modalEditUnit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar Unidade";
      this.clearForm();
      this.$store.dispatch("Unit/show", uuid).then(() => {
        this.item = this.unit;
        this.preview = this.unit.image;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-unit");
      });
    },
    searchCep() {
      if (this.item.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.cep}/json/`)
          .then((res) => {
            const data = res.data;
            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.uf = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const formData = new FormData();
        formData.append("image", this.item.image);
        formData.append("name", this.item.name);
        formData.append("email", this.item.email);
        formData.append("id_old", this.item.id_old);
        formData.append("phone_1", this.item.phone_1);
        formData.append("phone_2", this.item.phone_2);
        formData.append("cep", this.item.cep);
        formData.append("number", this.item.number);
        formData.append("complement", this.item.complement);
        formData.append("address", this.item.address);
        formData.append("neighborhood", this.item.neighborhood);
        formData.append("city", this.item.city);
        formData.append("uf", this.item.uf);
        formData.append(
          "status",
          this.item.status ? this.item.status.code : ""
        );

        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Unit/create", formData)
            .then(() => {
              this.getData();
              this.notify(
                "Unidade cadastrada com sucesso!",
                "UserCheckIcon",
                "success",
                "Agora sua escolha possui unidade cadastrada."
              );

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-unit");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
              this.notify(
                "Error ao cadastrar a unidade",
                "CoffeeIcon",
                "danger",
                "Verifique os dados e tente novamente."
              );
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Unit/update", { uuid: this.uuid, data: formData })
            .then(() => {
              this.getData();
              this.notify(
                "Unidade cadastrada com sucesso!",
                "UserCheckIcon",
                "success",
                "Agora sua escolha possui unidade cadastrada."
              );

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-unit");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
              this.notify(
                "Error ao cadastrar a unidade",
                "CoffeeIcon",
                "danger",
                "Verifique os dados e tente novamente."
              );
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        uuid: "",
        name: "",
        email: "",
        phone_1: "",
        phone_2: "",
        address: "",
        complement: "",
        city: "",
        number: "",
        cep: "",
        neighborhood: "",
        uf: "",
        image: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("Unit/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then((res) => {
          this.dataItems = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.currentPage = res.meta.current_page;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Unit/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Unidade foi deletada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("Unit", ["units", "unit", "loading", "deleted"]),
    ...mapState("Media", ["searchMaterials"]),
  },
};
</script>
